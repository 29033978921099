import { TFunction } from 'i18next';
import { setupNewI18nInstance, createLibTransHook } from '@insights-analytics/feature-translation';

const i18n = setupNewI18nInstance('feature-error-components');
i18n.init({
    ns: ['moduleErrorComponents'],
    defaultNS: 'moduleErrorComponents',
    fallbackNS: 'moduleErrorComponents',
    fallbackLng: 'en',
});

export const useLibTrans = createLibTransHook(i18n);

export type TType = TFunction<'moduleErrorComponents'>;
